import { useState } from 'react';
import apiClient from '../api/base';

export interface UserData {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  photo_uri: string;
  role: string;
  is_active: boolean;
  profile_image: string;
  receiving_email: string;
}

const useUsers = () => {
  const [error, setError] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [prvPage, setPrvPage] = useState<string | null>(null);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [allUsers, setAllUsers] = useState<UserData[]>([]);

  const getUserList = async () => {
    try {
      const resp = await apiClient.get('users/list/');
      const data = resp.data.results as UserData[];
      setPrvPage(resp.data.previous);
      setNextPage(resp.data.next);
      setCurrentPage(1);
      setAllUsers(data);
    } catch (error) {
      setError('Wystąpił problem podczas pobierania bazy użytkowników.');
      return [];
    }
  };

  const goToNextPage = async () => {
    if (nextPage) {
      try {
        const resp = await apiClient.get(nextPage);
        const data = resp.data.results as UserData[];
        setPrvPage(resp.data.previous);
        setNextPage(resp.data.next);
        setCurrentPage(prevState => prevState + 1);
        setAllUsers(data);
      } catch (error) {
        setError('Wystąpił problem podczas pobierania bazy użytkowników.');
        return [];
      }
    }
  };

  const goToPreviousPage = async () => {
    if (prvPage) {
      try {
        const resp = await apiClient.get(prvPage);
        const data = resp.data.results as UserData[];
        setPrvPage(resp.data.previous);
        setNextPage(resp.data.next);
        setCurrentPage(prevState => prevState - 1);
        setAllUsers(data);
      } catch (error) {
        setError('Wystąpił problem podczas pobierania bazy użytkowników.');
        return [];
      }
    }
  };

  const getParticularUserByFullName = async (fullName: string) => {
    try {
      const resp = await apiClient.get(`users/list/?full_name=${fullName}`);
      const data = resp.data.results as UserData[];
      setPrvPage(resp.data.previous);
      setNextPage(resp.data.next);
      setCurrentPage(1);
      setAllUsers(data);
    } catch (error) {
      setError('Wystąpił problem podczas pobierania bazy użytkowników.');
      return [];
    }
  };

  return {
    error,
    setError,
    getUserList,
    getParticularUserByFullName,
    currentPage,
    nextPage,
    prvPage,
    allUsers,
    goToNextPage,
    goToPreviousPage,
  };
};

export default useUsers;
