import apiClient from '../api/base';
import { useState } from 'react';

const useUpdateUser = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const update = async (userData: { [k: string]: any }) => {
    const payload = new FormData();

    for (const [key, value] of Object.entries(userData)) {
      payload.append(key, value);
    }

    setErrorMessage('');
    setMessage('');

    try {
      await apiClient.patch('users/', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('Dane użytkownika zostały zaktualizowane');
    } catch (error: any) {
      const status = error.response.status;
      if (status === 400) {
        const { errors } = error.response.data;

        if (errors.username && errors.email) {
          setErrorMessage(
            'Podana nazwa użytkownika oraz adres email został już wykorzystany'
          );
        } else if (errors.username) {
          setErrorMessage('Podana nazwa użytkownika została już wykorzystana');
        } else if (errors.email) {
          setErrorMessage('Podany adres email został już wykorzystany');
        }

        return;
      }

      if (status === 500) {
        setErrorMessage(
          'Wystąpił błąd serwera. Skontaktuj się z administratorem.'
        );
      }
    }
  };

  return {
    update,
    errorMessage,
    message,
  };
};

export default useUpdateUser;
