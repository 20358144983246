import React, { useState } from 'react';
import { PiFilesFill } from 'react-icons/pi';
import { VscAccount } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { UserData } from '../../../hooks/useUsers';
import FilesDialog from '../FilesDialog/FilesDialog';

export default function UserCard({
  id,
  username,
  first_name,
  last_name,
  email,
  photo_uri,
  role,
  is_active,
  profile_image,
  receiving_email,
}: UserData) {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);

  const profileButton = () => {
    navigate('/admin/user/profile', {
      state: {
        id: id,
        username: username,
        first_name: first_name,
        last_name: last_name,
        email: email,
        role: role,
        is_active: is_active,
        profile_image: profile_image,
        receiving_email: receiving_email,
      },
    });
  };

  const dddFilesButton = () => {
    setDialogOpen(true); // Open the dialog
  };

  return (
    <>
      <div className="card">
        <div className="p-2"></div>
        <div className="flex grow flex-col items-center px-4 pb-5 sm:px-5">
          <div className="avatar size-20">
            <img className="rounded-lg" src={profile_image} alt="avatar" />
          </div>

          <h3 className="pt-3 text-lg font-medium text-slate-700 dark:text-navy-100">
            {first_name ? first_name : ' '} {last_name}
          </h3>
          <p className="text-xs+">
            {role === 'User' ? 'Użytkownik' : 'Administrator'}
          </p>

          <div className="mt-6 grid w-full grid-cols-2 gap-2">
            <button
              className="btn space-x-2 bg-primary px-0 font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
              onClick={profileButton}>
              <VscAccount />
              <span>Profil</span>
            </button>

            <button
              className="btn space-x-2 bg-slate-150 px-0 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
              onClick={dddFilesButton}>
              <PiFilesFill />
              <span>Pliki DDD</span>
            </button>
          </div>
        </div>
      </div>

      {/* Render the dialog */}
      <FilesDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        email={email}
      />
    </>
  );
}
