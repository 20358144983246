import RoutesInterface from '../RoutesInterface';
import { Home, UsersDashboard, CreateUserForm } from '../../pages';
import { UserProfilePage } from '../../pages/Admin';

const AdminRoutes: RoutesInterface[] = [
  {
    path: '/admin/home',
    component: <Home />,
    settings: {
      usePadding: false,
      pageTitle: 'Panel administratora',
      secure: true,
      adminRoute: true,
    },
  },
  {
    path: '/admin/users',
    component: <UsersDashboard />,
    settings: {
      usePadding: true,
      pageTitle: 'Użytkownicy',
      secure: true,
      adminRoute: true,
    },
  },
  {
    path: '/admin/users/create',
    component: <CreateUserForm />,
    settings: {
      usePadding: true,
      pageTitle: 'Stwórz użytkownika',
      secure: true,
      adminRoute: true,
    },
  },
  {
    path: '/admin/user/profile',
    component: <UserProfilePage />,
    settings: {
      usePadding: true,
      pageTitle: 'Profil użytkownika',
      secure: true,
      adminRoute: true,
    },
  },
];

export default AdminRoutes;
