import React from "react";

interface SidebarIconRefProps {
  href: string;
  icon: React.ReactNode;
}

export default function SidebarIconRef({ href, icon }: SidebarIconRefProps) {
  return (
    <a
      href={href}
      className="flex size-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
      data-tooltip-placement="right"
    >
      {icon}
    </a>
  );
}
