import { Helmet, HelmetProvider } from "react-helmet-async";

interface PageMetaProps {
  title: string;
}

export default function PageMetaData({ title }: PageMetaProps) {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
        </Helmet>
      </HelmetProvider>
    </>
  );
}
