import React, { useContext, useEffect, useRef, forwardRef } from 'react';
import './notifications.css';
import NotificationItem from './NotificationItem';
import { NotificationContext } from '../../../context/NotificationContext/NotificationContext';
import { FaArrowRight } from 'react-icons/fa';
import { FaArrowLeft } from 'react-icons/fa';
import useNotifications from '../../../hooks/useNotifications';
import { RxAvatar } from 'react-icons/rx';

interface NotificationsProps {
  isOpen: boolean;
}

const NotificationItemColors = [
  [
    'bg-secondary/10 dark:bg-secondary-light/15',
    'text-secondary dark:text-secondary-light',
  ],
  ['bg-info/10 dark:bg-info/15', 'text-info'],
  [
    'bg-primary/10 dark:bg-accent-light/15',
    'text-primary dark:text-accent-light',
  ],
  ['bg-success/10 dark:bg-success/15', 'text-success'],
  ['bg-warning/10 dark:bg-warning/15', 'text-warning'],
  ['bg-error/10 dark:bg-error/15', 'text-error'],
];

const Notifications = forwardRef<HTMLDivElement, NotificationsProps>(
  ({ isOpen }, ref) => {
    const hasMounted = useRef(false);
    const notificationContext = useContext(NotificationContext);
    const notificationsHook = useNotifications();

    useEffect(() => {
      hasMounted.current = true;

      return () => {
        hasMounted.current = false;
      };
    }, []);

    if (!hasMounted.current) {
      return null;
    }

    const renderNotifications = () => {
      if (notificationsHook.error) {
        return (
          <p className="text-red-500 text-sm">{notificationsHook.error}</p>
        );
      }

      if (notificationContext?.notifications) {
        const noticationsToRender = notificationContext.notifications;

        return noticationsToRender.map((notification, i) => (
          <NotificationItem
            key={notification.notification_id}
            created_at={notification.created_at}
            user_fullname={
              notification.created_by_first_name +
              ' ' +
              notification.created_by_last_name
            }
            notification_id={notification.notification_id}
            notification_type={notification.notification_type}
            icon={
              <RxAvatar
                className={`size-6 ${NotificationItemColors[i % NotificationItemColors.length][1]}`}
              />
            }
            colors={
              NotificationItemColors[i % NotificationItemColors.length][0]
            }
          />
        ));
      }
    };

    return (
      <div
        className={`popper-root notification-popup backface-hidden ${isOpen ? 'show' : 'slide-out'}`}
        ref={ref}>
        <div className="popper-box mx-4 mt-40 md:mt-2 flex max-h-[calc(60vh-3rem)] w-[calc(100vw-2rem)] flex-col rounded-lg border border-slate-150 bg-white shadow-soft dark:border-navy-800 dark:bg-navy-700 dark:shadow-soft-dark sm:m-0 sm:w-80 backface-hidden">
          <div className="rounded-t-lg bg-slate-100 text-slate-600 dark:bg-navy-800 dark:text-navy-200 pb-2 ">
            {/* Header */}
            <div className="flex items-center justify-between px-4 pt-2">
              <div className="flex items-center space-x-2">
                <h3 className="font-medium text-slate-700 dark:text-navy-100">
                  Powiadomienia
                </h3>
                <div className="badge h-5 rounded-full bg-primary/10 px-1.5 text-primary dark:bg-accent-light/15 dark:text-accent-light">
                  {notificationContext?.allCount || 0}
                </div>
                <button
                  className="badge h-5 rounded-full bg-primary/10 px-1.5 text-primary dark:bg-accent-light/15 dark:text-accent-light"
                  onClick={notificationContext?.markCurrentAsRead}>
                  Odczytaj
                </button>
                <button
                  className={`badge h-5 rounded-full px-1.5 ${
                    notificationContext?.previousPage
                      ? 'bg-primary/10 text-primary dark:bg-accent-light/15 dark:text-accent-light'
                      : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  }`}
                  onClick={
                    notificationContext?.previousPage
                      ? notificationContext?.getPrvPage
                      : undefined
                  }
                  disabled={!notificationContext?.previousPage}>
                  <FaArrowLeft />
                </button>
                <button
                  className={`badge h-5 rounded-full px-1.5 ${
                    notificationContext?.nextPage
                      ? 'bg-primary/10 text-primary dark:bg-accent-light/15 dark:text-accent-light'
                      : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  }`}
                  onClick={
                    notificationContext?.nextPage
                      ? notificationContext?.getNextPage
                      : undefined
                  }
                  disabled={!notificationContext?.nextPage}>
                  <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
          {/* List */}
          <div className="tab-content flex flex-col overflow-hidden backface-hidden">
            <div className="is-scrollbar-hidden backface-hidden space-y-4 overflow-y-auto px-4 py-4">
              {renderNotifications()}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default Notifications;
