import { useState, useEffect, useCallback } from 'react';
import apiClient from '../api/base';

interface IFile {
  dddfile: string;
  user: number;
  upload_date: string;
  index?: number;
}

const useFiles = (email: string, dialogOpen: boolean) => {
  const [files, setFiles] = useState<IFile[]>([]);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [next, setNext] = useState<string | null>(null);
  const [previous, setPrevious] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10;

  const fetchFiles = useCallback(
    async (page: number = 1) => {
      setLoading(true);
      try {
        const resp = await apiClient.get(
          `ddd/admin/?user__email=${email}&page=${page}`
        );
        const data = preProcessData(resp.data.results as IFile[], page);
        setFiles(data);
        setNext(resp.data.next);
        setPrevious(resp.data.previous);
        setCurrentPage(page);
      } catch (error) {
        setError('Wystąpił problem podczas pobierania plików.');
      } finally {
        setLoading(false);
      }
    },
    [email]
  );

  const preProcessData = (data: IFile[], pageNo: number) => {
    return data.map((file, idx) => ({
      ...file,
      index: (pageNo - 1) * itemsPerPage + idx + 1,
    }));
  };

  const goToNextPage = async () => {
    if (next) {
      await fetchFiles(currentPage + 1);
    }
  };

  const goToPreviousPage = async () => {
    if (previous && currentPage > 1) {
      await fetchFiles(currentPage - 1);
    }
  };

  useEffect(() => {
    fetchFiles(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, fetchFiles, dialogOpen]);

  return {
    files,
    error,
    loading,
    next,
    previous,
    goToNextPage,
    goToPreviousPage,
    currentPage,
  };
};

export default useFiles;
