import React, { createContext, useEffect, useState } from 'react';
import { Notification } from '../../hooks/useNotifications';
import useNotifications from '../../hooks/useNotifications';

interface NotificationContextType {
  notifications: Notification[];
  getNotifications: (page: number) => Promise<void>;
  markCurrentAsRead: () => Promise<void>;
  getNextPage: () => Promise<void>;
  getPrvPage: () => Promise<void>;
  nextPage: boolean;
  previousPage: boolean;
  allCount: number;
}

export const NotificationContext = createContext<
  NotificationContextType | undefined
>(undefined);

interface ContextProps {
  children: React.ReactNode;
}

const ProvideNotification = ({ children }: ContextProps) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nextPage, setNextPage] = useState<boolean>(false);
  const [previousPage, setPreviousPage] = useState<boolean>(false);
  const [allCount, setAllCount] = useState<number>(0);
  const notificationsHook = useNotifications();

  const getNotifications = async (page: number) => {
    const data = await notificationsHook.getNotifications(page);
    setCurrentPage(page);
    if (data?.results) {
      setAllCount(data.all_count);
      if (data.next) {
        setNextPage(true);
      } else {
        setNextPage(false);
      }
      if (data.previous) {
        setPreviousPage(true);
      } else {
        setPreviousPage(false);
      }
      setNotifications(data.results);
    }
  };

  const getNextPage = async () => {
    await getNotifications(currentPage + 1);
  };

  const getPrvPage = async () => {
    await getNotifications(currentPage - 1);
  };

  const markCurrentAsRead = async () => {
    if (notifications.length > 0) {
      let ids: number[] = [];

      for (let not of notifications) {
        ids.push(not.notification_id);
      }

      await notificationsHook.markMultipleAsRead(ids);

      const updated = notifications.filter(
        (notification, _) => !ids.includes(notification.notification_id)
      );
      setNotifications(updated);
      setAllCount(prv => prv - ids.length);

      if (nextPage) {
        getPrvPage();
      } else if (previousPage) {
        getNextPage();
      } else {
        getNotifications(0);
      }

      if (previousPage) {
        await getPrvPage();
      } else if (nextPage) {
        await getNextPage();
      }
    }
  };

  useEffect(() => {
    getNotifications(currentPage);
  }, []);

  useEffect(() => {}, [notifications]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        getNotifications,
        markCurrentAsRead,
        getNextPage,
        getPrvPage,
        nextPage,
        previousPage,
        allCount,
      }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default ProvideNotification;
