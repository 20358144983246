import apiClient from '../api/base';
import { useState } from 'react';

export interface Notification {
  created_at: string;
  created_by_first_name: string;
  created_by_last_name: string;
  notification_id: number;
  notification_type: 'new_file';
}

export interface PaginatedNotificationResults {
  count: number;
  next: number | null;
  previous: number | null;
  results: Notification[];
  all_count: number;
}

const useNotifications = () => {
  const [error, setError] = useState<string>('');

  const getNotifications = async (page: number = 0) => {
    try {
      let response;
      if (page > 0) {
        response = await apiClient.get(`notifications/`, {
          params: { page: page },
        });
      } else {
        response = await apiClient.get(`notifications/`);
      }

      return response.data as PaginatedNotificationResults;
    } catch (e) {
      setError('Wystąpił błąd podczas pobierania notyfikacji');
    }
  };

  const markNotificationAsRead = async (notificationId: number) => {
    try {
      const response = await apiClient.patch(`notifications/${notificationId}`);

      return response.data;
    } catch (e) {
      setError('Wystąpił błąd podczas pobierania notyfikacji');
    }
  };

  const markMultipleAsRead = async (notificationIds: number[]) => {
    try {
      const response = await apiClient.post('notifications/read/', {
        notification_ids: notificationIds,
      });

      return response.data;
    } catch (e) {
      setError('Wystąpił błąd podczas pobierania notyfikacji');
    }
  };

  return {
    getNotifications,
    markMultipleAsRead,
    markNotificationAsRead,
    error,
    setError,
  };
};

export default useNotifications;
