import React, { useState } from 'react';
import { Navigation } from '../../../components';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import useCreateUser from '../../../hooks/useCreateUser';

// Define the Zod validation schema
const schema = z.object({
  username: z.string().min(6, 'Login musi mieć co najmniej 6 znaków'),
  password: z.string().min(8, 'Hasło musi mieć co najmniej 8 znaków'),
  first_name: z.string().min(1, 'Imię nie może być puste'),
  last_name: z.string().min(1, 'Nazwisko nie może być puste'),
  email: z.string().email('Niepoprawny adres email'),
  role: z.enum(['User', 'Admin']),
  profile_image: z.instanceof(File).optional(),
});

/// Define the TypeScript type for form data
type FormData = z.infer<typeof schema>;

export default function CreateUserForm() {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      username: '',
      password: '',
      first_name: '',
      last_name: '',
      email: '',
      role: 'User',
      profile_image: undefined,
    },
  });
  const [fileName, setFileName] = useState<string>('');

  const createUser = useCreateUser();

  const onSubmit = async (data: FormData) => {
    // Prepare the data object without FormData
    const payload = {
      username: data.username,
      password: data.password,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      role: data.role,
      profile_image: data.profile_image,
    };

    await createUser.create(payload);
    reset();
  };

  const cancel = () => {
    reset();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileName(file.name);
      setValue('profile_image', file);
    }
  };

  return (
    <>
      <Navigation />
      <div className="flex items-center space-x-4 py-5 lg:py-6">
        <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
          Stwórz nowego użytkownika
        </h2>
      </div>
      <div className="col-span-12 sm:col-span-8">
        <div className="card p-4 sm:p-5">
          {createUser.errorMessage && (
            <p className="text-red-500 text-sm pb-1">
              {createUser.errorMessage}
            </p>
          )}
          {createUser.success && (
            <p className="text-green-500 text-sm pb-1">{createUser.success}</p>
          )}
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <label className="block">
                <span>Nazwa użytkownika</span>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      placeholder="Login użytkownika"
                    />
                  )}
                />
                {errors.username && (
                  <p className="text-red-500 text-sm">
                    {errors.username.message}
                  </p>
                )}
              </label>
            </div>
            <div>
              <label className="block">
                <span>Hasło</span>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="password"
                      className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      placeholder="Hasło użytkownika"
                    />
                  )}
                />
                {errors.password && (
                  <p className="text-red-500 text-sm">
                    {errors.password.message}
                  </p>
                )}
              </label>
            </div>
            <div>
              <label className="block">
                <span>Imię</span>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      placeholder="Imię użytkownika"
                    />
                  )}
                />
                {errors.first_name && (
                  <p className="text-red-500 text-sm">
                    {errors.first_name.message}
                  </p>
                )}
              </label>
            </div>
            <div>
              <label className="block">
                <span>Nazwisko</span>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      placeholder="Nazwisko użytkownika"
                    />
                  )}
                />
                {errors.last_name && (
                  <p className="text-red-500 text-sm">
                    {errors.last_name.message}
                  </p>
                )}
              </label>
            </div>
            <div>
              <label className="block">
                <span>Email</span>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="email"
                      className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                      placeholder="Email użytkownika"
                    />
                  )}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email.message}</p>
                )}
              </label>
            </div>
            <div>
              <label className="block">
                <span>Rola</span>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <select
                      {...field}
                      className="form-select peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent">
                      <option value="User">Użytkownik</option>
                      <option value="Admin">Administrator</option>
                    </select>
                  )}
                />
                {errors.role && (
                  <p className="text-red-500 text-sm">{errors.role.message}</p>
                )}
              </label>
              <Controller
                name="profile_image"
                control={control}
                render={({ field }) => (
                  <div className="flex items-start gap-2 flex-col">
                    <label className="btn mt-4 relative bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                      <input
                        type="file"
                        className="pointer-events-none absolute inset-0 h-full w-full opacity-0"
                        onChange={e => {
                          handleFileChange(e);
                          field.onChange(e.target.files?.[0] || null);
                        }}
                      />
                      <span className="flex items-center space-x-2">
                        <i className="fa-solid fa-cloud-arrow-up text-base"></i>
                        <span>Wybierz zdjęcie profilu</span>
                      </span>
                    </label>
                    {fileName.length > 0 && <p>{fileName}</p>}
                  </div>
                )}
              />
              {errors.profile_image && (
                <p className="text-red-500 text-sm">
                  {errors.profile_image.message}
                </p>
              )}
            </div>
            <div className="flex space-x-2">
              <button
                type="button"
                className="btn space-x-5 w-52 bg-slate-150 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                onClick={cancel}>
                <span>Anuluj</span>
              </button>
              <button
                type="submit"
                className="btn space-x-5 w-52 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                <span>Stwórz</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
