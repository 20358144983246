import React, { useEffect, useState } from 'react';
import { PageMetaData, ProgressBar } from '../../components';
// import { NotFound } from '../../pages';
import useAuth from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';

interface ProtectetRouteRendererProps {
  pageTitle: string;
  usePadding: boolean;
  secure: boolean;
  adminRoute: boolean;
  component: React.ReactNode;
}

export default function ProtectedRouteRenderer({
  pageTitle,
  usePadding,
  secure,
  adminRoute,
  component,
}: ProtectetRouteRendererProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  // const [isLogged, setIsLogged] = useState<boolean>(false);

  const auth = useAuth();

  useEffect(() => {
    const checkAuth = async () => {
      // setIsLoading(true);
      try {
        // #TODO - Improve this logic

        // const loggedStatus = await auth?.isLogged();
        const adminStatus = await auth?.isAdmin();
        // setIsLogged(loggedStatus as boolean);
        setHasAccess(adminStatus as boolean);
      } catch (error) {
        setHasAccess(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [auth, secure]);

  if (isLoading) {
    return <ProgressBar />;
  }

  if (adminRoute && !hasAccess) {
    return <Navigate to="/" />;
  }

  if (hasAccess && !secure) {
    return <Navigate to="/admin/home" />;
  }

  // Rendering main part of component
  // Adding padding to component
  let componentToRender;
  if (usePadding) {
    componentToRender = (
      <>
        <main className="main-content w-full pb-8 px-[var(--margin-x)]">
          <PageMetaData title={pageTitle} />
          {component}
        </main>
      </>
    );
  } else {
    // Component without padding
    componentToRender = (
      <>
        <PageMetaData title={pageTitle} />
        <main className="main-content w-full pb-8">{component}</main>
      </>
    );
  }

  return componentToRender;
}
