import apiClient from '../api/base';
import { useState } from 'react';

interface UserData {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  profile_image: File | undefined;
}

const useCreateUser = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  const create = async (userData: UserData) => {
    setErrorMessage('');
    setSuccess('');
    const formData = new FormData();

    formData.append('username', userData.username);
    formData.append('password', userData.password);
    formData.append('first_name', userData.first_name);
    formData.append('last_name', userData.last_name);
    formData.append('email', userData.email);
    formData.append('role', userData.role);

    if (userData.profile_image) {
      formData.append('profile_image', userData.profile_image);
    }

    try {
      await apiClient.post('users/', userData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccess(`Użytkownik o nazwie ${userData.username} został utworzony`);
    } catch (error: any) {
      const { errors } = error.response.data;

      if (errors.username && errors.email) {
        setErrorMessage(
          'Podana nazwa użytkownika oraz adres email został już wykorzystany'
        );
      } else if (errors.username) {
        setErrorMessage('Podana nazwa użytkownika została już wykorzystana');
      } else if (errors.email) {
        setErrorMessage('Podany adres email został już wykorzystany');
      }
    }
  };

  return {
    create,
    errorMessage,
    success,
    setSuccess,
  };
};

export default useCreateUser;
