import React, { useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { UserData } from '../../../hooks/useUsers';
import useUpdateUser from '../../../hooks/useUpdateUser';
import { useNavigate } from 'react-router-dom';
import useDeleteUser from '../../../hooks/useDeleteUser';
import useLastCardDownload from '../../../hooks/useLastCardDownload';

const basicDataSchema = z.object({
  username: z
    .string()
    .min(6, 'Nazwa użytkownika musi mieć co najmniej 6 znaków'),
  first_name: z.string().min(1, 'Imię nie może być puste'),
  last_name: z.string().min(1, 'Nazwisko nie może być puste'),
  email: z.string().email('Musisz wprowadzić poprawny adres email'),
  receiving_email: z.string().email('Musisz wprowadzić poprawny adres email'),
  is_active: z.boolean(),
  role: z.enum(['Admin', 'User']).optional(),
  profile_image: z.instanceof(File).optional(),
});

const resetPasswordSchema = z
  .object({
    password: z.string().min(8, 'Nowe hasło musi mieć co najmniej 8 znaków'),
    confirmPassword: z.string(),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Hasła muszą się zgadzać',
    path: ['confirmPassword'],
  });

type BasicFormData = z.infer<typeof basicDataSchema>;
type ChangePasswordData = z.infer<typeof resetPasswordSchema>;

export default function UserProfile({
  email,
  username,
  first_name,
  last_name,
  id,
  role,
  is_active,
  profile_image,
  receiving_email,
}: UserData) {
  const basicDefaultValues = {
    username,
    first_name,
    last_name,
    email,
    receiving_email,
    is_active: is_active as boolean,
    role: role as 'Admin' | 'User',
    profile_image: undefined,
  };

  const {
    control: basicDataControl,
    handleSubmit: basicDataHandleSubmit,
    reset: resetBasicData,
    formState: { errors },
    setValue: basicFormSetValue,
  } = useForm<BasicFormData>({
    resolver: zodResolver(basicDataSchema),
    defaultValues: basicDefaultValues,
  });

  const {
    control: passwordDataControl,
    handleSubmit: passwordDataHandleSubmit,
    reset: resetPasswordData,
    formState: { errors: passErrors },
  } = useForm<ChangePasswordData>({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const inputFile = useRef(null);
  const navigate = useNavigate();
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [uploadedImg, setUploadedImg] = useState<File | undefined>(undefined);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openDeleteModal = () => {
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
  };

  const updateHook = useUpdateUser();
  const deleteHook = useDeleteUser();
  const lastCardDownloadHook = useLastCardDownload(id);

  const changeUserData = async (data: BasicFormData) => {
    const areDefault =
      JSON.stringify(data) === JSON.stringify(basicDefaultValues);

    if (areDefault) {
      return;
    }

    let updateData: { [k: string]: any } = {};
    for (const [key, value] of Object.entries(data)) {
      if (value !== basicDefaultValues[key as keyof BasicFormData]) {
        updateData[key] = value;
      }
    }

    updateData.id = id;
    await updateHook.update(updateData);

    navigate('/admin/users');
  };

  const deleteUser = async () => {
    const status = await deleteHook.deleteUserWithId(id);
    if (status === 204) {
      navigate('/admin/users');
      deleteHook.setError('');
    }
    setIsModalOpen(false);
  };

  const changePasswordData = (data: ChangePasswordData) => {
    let updatePasswordData: { [k: string]: any } = {};
    updatePasswordData.id = id;
    updatePasswordData.password = data.password;

    resetPasswordData();
    setShowPasswordFields(false);
    updateHook.update(updatePasswordData);
  };

  const cancelChangingPassword = () => {
    resetPasswordData();
  };

  const cancelBasicData = () => {
    resetBasicData();
    navigate('/admin/users');
  };

  const togglePasswordFields = () => {
    resetPasswordData();
    setShowPasswordFields(!showPasswordFields);
  };

  return (
    <>
      <div className="col-span-12 lg:col-span-8">
        {updateHook.errorMessage ? (
          <p className="text-red-500 my-2">{updateHook.errorMessage}</p>
        ) : updateHook.message ? (
          <p className="text-green-500 my-2">{updateHook.message}</p>
        ) : (
          <p className="my-2">&nbsp;</p>
        )}
        {deleteHook.errorMessage ? (
          <p className="text-red-500 my-2">{deleteHook.errorMessage}</p>
        ) : (
          ''
        )}
        {/* Modal to delete user */}
        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-100">
            <div className="bg-white rounded-lg p-6 max-w-md mx-auto dark:bg-navy-700">
              <h3 className="text-lg font-medium text-slate-700 dark:text-navy-100">
                Potwierdzenie usunięcia
              </h3>
              <p className="mt-4 text-slate-600 dark:text-navy-200">
                Czy na pewno chcesz usunąć tego użytkownika? Tej operacji nie
                można cofnąć.
              </p>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={closeDeleteModal}
                  className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                  Anuluj
                </button>
                <button
                  onClick={deleteUser}
                  className="btn min-w-[7rem] rounded-full bg-red-500 font-medium text-white hover:bg-red-600 focus:bg-red-600 active:bg-red-700 dark:bg-red-700 dark:hover:bg-red-800 dark:focus:bg-red-800 dark:active:bg-red-900">
                  Usuń
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="card">
          <div className="flex flex-col items-center space-y-4 border-b border-slate-200 p-4 dark:border-navy-500 sm:flex-row sm:justify-between sm:space-y-0 sm:px-5">
            <h2 className="text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">
              Ustawienia konta
            </h2>
            <div className="flex justify-center space-x-2">
              <button
                onClick={cancelBasicData}
                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                Anuluj
              </button>
              <button
                onClick={basicDataHandleSubmit(changeUserData)}
                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                Zapisz
              </button>
              <button
                onClick={() => {
                  setIsModalOpen(true);
                }}
                className="btn min-w-[7rem] rounded-full bg-red-500 font-medium text-white hover:bg-red-600 focus:bg-red-600 active:bg-red-700 dark:bg-red-700 dark:hover:bg-red-800 dark:focus:bg-red-800 dark:active:bg-red-900">
                Usuń użytkownika
              </button>
            </div>
          </div>

          <div className="p-4 sm:p-5">
            <div className="flex flex-col">
              <span className="text-base font-medium text-slate-600 dark:text-navy-100">
                Zdjęcie
              </span>
              <div className="avatar mt-1.5 size-20">
                <img
                  className="mask rounded-lg"
                  src={
                    uploadedImg
                      ? URL.createObjectURL(uploadedImg)
                      : profile_image
                  }
                  alt="avatar"
                />
                <div className="absolute bottom-0 right-0 flex items-center justify-center rounded-full bg-white dark:bg-navy-700">
                  <label htmlFor="profile_image">
                    <button
                      onClick={() => {
                        (inputFile.current as any).click();
                      }}
                      className="btn size-6 rounded-full border border-slate-200 p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:border-navy-500 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                      </svg>
                      <input
                        ref={inputFile}
                        type="file"
                        id="profile_image"
                        name="profile_image"
                        className="hidden"
                        accept="image/*"
                        onChange={e => {
                          const file = e.target.files?.[0] || undefined;
                          basicFormSetValue('profile_image', file);
                          setUploadedImg(file);
                        }}
                      />
                    </button>
                  </label>
                </div>
              </div>
            </div>
            <div className="my-7 h-px bg-slate-200 dark:bg-navy-500"></div>
            <div className="my-7">
              <h4>Data ostatniego sczytu z karty: {lastCardDownloadHook}</h4>
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
              {/* Username */}
              <label className="block">
                <span>Nazwa użytkownika</span>
                <span className="relative mt-1.5 flex">
                  <Controller
                    name="username"
                    control={basicDataControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-input peer w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Wprowadź nazwę użytkownika"
                        type="text"
                      />
                    )}
                  />
                  <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <i className="fa-regular fa-user text-base"></i>
                  </span>
                </span>
                {errors.username && (
                  <p className="text-red-500 text-sm">
                    {errors.username.message}
                  </p>
                )}
              </label>
              {/* First name */}
              <label className="block">
                <span>Imię</span>
                <span className="relative mt-1.5 flex">
                  <Controller
                    name="first_name"
                    control={basicDataControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-input peer w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Wprowadź imię"
                        type="text"
                      />
                    )}
                  />
                  <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <i className="fa-solid fa-signature text-base"></i>
                  </span>
                </span>
                {errors.first_name && (
                  <p className="text-red-500 text-sm">
                    {errors.first_name.message}
                  </p>
                )}
              </label>
              {/* Last name */}
              <label className="block">
                <span>Nazwisko</span>
                <span className="relative mt-1.5 flex">
                  <Controller
                    name="last_name"
                    control={basicDataControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-input peer w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Wprowadź nazwisko"
                        type="text"
                      />
                    )}
                  />
                  <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <i className="fa-solid fa-signature text-base"></i>
                  </span>
                </span>
                {errors.last_name && (
                  <p className="text-red-500 text-sm">
                    {errors.last_name.message}
                  </p>
                )}
              </label>
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-2">
              {/* Email */}
              <label className="block">
                <span>Email</span>
                <span className="relative mt-1.5 flex">
                  <Controller
                    name="email"
                    control={basicDataControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-input peer w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Wprowadź email"
                        type="text"
                      />
                    )}
                  />
                  <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <i className="fa-solid fa-envelope text-base"></i>
                  </span>
                </span>
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email.message}</p>
                )}
              </label>

              {/* Role */}
              <label className="block">
                <span>Rola</span>
                <span className="relative mt-1.5 flex">
                  <Controller
                    name="role"
                    control={basicDataControl}
                    render={({ field }) => (
                      <select
                        {...field}
                        className="form-input peer w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent">
                        <option value="User">Użytkownik</option>
                        <option value="Admin">Administrator</option>
                      </select>
                    )}
                  />
                  <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <i className="fa-solid fa-user-tie text-base"></i>
                  </span>
                </span>
                {errors.role && (
                  <p className="text-red-500 text-sm">{errors.role.message}</p>
                )}
              </label>
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-2">
              {/* Receiving email */}
              <label className="block">
                <span>Email do wysyłania plików ddd</span>
                <span className="relative mt-1.5 flex">
                  <Controller
                    name="receiving_email"
                    control={basicDataControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-input peer w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Wprowadź adres email"
                        type="text"
                      />
                    )}
                  />
                  <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <i className="fa-solid fa-user-tie text-base"></i>
                  </span>
                </span>
                {errors.receiving_email && (
                  <p className="text-red-500 text-sm">
                    {errors.receiving_email.message}
                  </p>
                )}
              </label>
              {/* Checkbox */}
              <label className="block ml-1">
                <span className="relative mt-1.5 flex items-center space-x-2">
                  <Controller
                    name="is_active"
                    control={basicDataControl}
                    render={({ field: { onChange, value } }) => (
                      <label className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          checked={value}
                          onChange={e => onChange(e.target.checked)}
                          className="form-checkbox is-basic size-5 rounded border-slate-400/70 checked:border-slate-500 checked:bg-slate-500 hover:border-slate-500 focus:border-slate-500 dark:border-navy-400 dark:checked:bg-navy-400"
                        />
                        <span>Aktywne konto</span>
                      </label>
                    )}
                  />
                </span>
                {errors.is_active && (
                  <p className="text-red-500 text-sm">
                    {errors.is_active.message}
                  </p>
                )}
              </label>
            </div>
            <div className="my-7 h-px bg-slate-200 dark:bg-navy-500"></div>
            <div className="flex justify-center">
              <button
                onClick={togglePasswordFields}
                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                {showPasswordFields ? 'Zamknij' : 'Resetuj hasło'}
              </button>
            </div>
            {showPasswordFields && (
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-4">
                {/* Password */}
                <label className="block">
                  <span>Nowe Hasło</span>
                  <span className="relative mt-1.5 flex">
                    <Controller
                      name="password"
                      control={passwordDataControl}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="form-input peer w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                          placeholder="Wprowadź nowe hasło"
                          type="password"
                        />
                      )}
                    />
                    <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                      <i className="fa-solid fa-lock text-base"></i>
                    </span>
                  </span>
                  {passErrors.password && (
                    <p className="text-red-500 text-sm">
                      {passErrors.password.message}
                    </p>
                  )}
                </label>
                {/* Confirm Password */}
                <label className="block">
                  <span>Potwierdź Hasło</span>
                  <span className="relative mt-1.5 flex">
                    <Controller
                      name="confirmPassword"
                      control={passwordDataControl}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="form-input peer w-full rounded-full border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                          placeholder="Potwierdź nowe hasło"
                          type="password"
                        />
                      )}
                    />
                    <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                      <i className="fa-solid fa-lock text-base"></i>
                    </span>
                  </span>
                  {passErrors.confirmPassword && (
                    <p className="text-red-500 text-sm">
                      {passErrors.confirmPassword.message}
                    </p>
                  )}
                </label>
                <button
                  onClick={cancelChangingPassword}
                  className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
                  Anuluj
                </button>
                <button
                  onClick={passwordDataHandleSubmit(changePasswordData)}
                  className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                  Zapisz
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
