import {
  UserStatistics,
  DeliveryStatus,
  GoalStatus,
  Navigation,
  InformationGrid,
} from '../../../components';

export default function Home() {
  return (
    <>
      <Navigation />
      <div className="mt-4 grid grid-cols-12 gap-4 px-[var(--margin-x)] transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
        <UserStatistics />
        <InformationGrid />
        <DeliveryStatus />
        <GoalStatus />
      </div>
    </>
  );
}
