import React, { useEffect, useState } from 'react';
import { RiArrowLeftWideFill } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

interface SidebarPanelProps {
  isSidebarExpanded: boolean;
  toggleSidebar: () => void;
}

const SidebarPanel: React.FC<SidebarPanelProps> = ({
  isSidebarExpanded,
  toggleSidebar,
}) => {
  const auth = useAuth();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');
  const [shouldShowSidebarWidth, setShouldShowSidebarWidth] = useState(
    window.innerWidth >= 768
  );
  const navigate = useNavigate();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setShouldShowSidebarWidth(window.innerWidth >= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const logOut = async () => {
    const hasLoggedOut = await auth?.logOut();
    if (hasLoggedOut) {
      navigate('/');
    }
  };

  return (
    <div
      className={`fixed left-full top-0 h-full w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
        isSidebarExpanded ? 'translate-x-0' : '-translate-x-full'
      } ${shouldShowSidebarWidth ? 'pl-[var(--main-sidebar-width)]' : ''} dark:bg-navy-750`}>
      <div className="flex h-18 w-full items-center justify-between pl-4 pr-1 align-center">
        <p className="text-base tracking-wider text-slate-800 dark:text-navy-100">
          Panele
        </p>
        <RiArrowLeftWideFill
          className="btn h-7 w-7 rounded-full p-0 text-primary hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-accent-light/80 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 size-6"
          onClick={toggleSidebar}
        />
      </div>
      <div className="my-3 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div>
      <ul className="flex flex-1 flex-col px-4 font-inter">
        <li>
          <a
            href="/admin/home"
            className={`flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out ${
              activeLink === '/admin/home'
                ? 'font-medium text-primary dark:text-accent-light'
                : 'text-slate-600 hover:text-slate-900 dark:text-navy-200 dark:hover:text-navy-50'
            }`}>
            Panel główny
          </a>
        </li>
        <li>
          <a
            href="/admin/users"
            className={`flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out ${
              activeLink === '/admin/users'
                ? 'font-medium text-primary dark:text-accent-light'
                : 'text-slate-600 hover:text-slate-900 dark:text-navy-200 dark:hover:text-navy-50'
            }`}>
            Użytkownicy
          </a>
        </li>
        <li>
          <a
            className={`flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out cursor-pointer`}
            onClick={logOut}>
            Wyloguj się
          </a>
        </li>
      </ul>
      <div className="my-3 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div>
      <ul className="flex flex-1 flex-col px-4 font-inter">
        <li>
          <a
            href="https://mtacho.pl/produkt/rozliczanie-czasu-pracy-kierowcow/"
            target="_blank"
            className={`flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out `}>
            MTacho
          </a>
        </li>
        <li>
          <a
            href="https://allegro.pl/uzytkownik/Tachoss"
            target="_blank"
            className={`flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out `}>
            Allegro
          </a>
        </li>
        <li>
          <a
            href="https://mtacho.pl/sklep/"
            target="_blank"
            className={`flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out `}>
            MTacho - Sklep
          </a>
        </li>
        <li>
          <a
            href="https://mtacho.pl/produkt/sprawdzanie-naruszen-czasu-pracy-itd-4/"
            target="_blank"
            className={`flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out `}>
            MTacho - Produkty
          </a>
        </li>
        <li>
          <a
            href={process.env.PUBLIC_URL + '/dddreader.zip'}
            target="_blank"
            className={`flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out `}
            download={true}>
            Pobierz skrypt - MacOS
          </a>
        </li>
        <li>
          <a
            href={process.env.PUBLIC_URL + '/windows-reader.zip'}
            target="_blank"
            className={`flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out `}
            download={true}>
            Pobierz skrypt - Windows
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SidebarPanel;
