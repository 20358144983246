import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdminRoutes from './admin/AdminRoutes';
import DefaultRoutes from './default/DefaultRoutes';
import ProtectedRouteRenderer from './ProtectedRouteRenderer/ProtectedRouteRenderer';
import RoutesInterface from './RoutesInterface';

export default function Outlet() {
  const renderRoutes = (routes: RoutesInterface[]) =>
    routes.map((route, id) => (
      <Route
        key={id}
        path={route.path}
        element={
          <ProtectedRouteRenderer
            component={route.component}
            {...route.settings}
          />
        }
      />
    ));

  return (
    <>
      <div className="min-h-100vh flex grow bg-slate-50 dark:bg-navy-900">
        <BrowserRouter>
          <Routes>
            {renderRoutes(DefaultRoutes)}
            {renderRoutes(AdminRoutes)}
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}
