import RoutesInterface from '../RoutesInterface';
import { NotFound, Login } from '../../pages/';

const DefaultRoutes: RoutesInterface[] = [
  {
    path: '/',
    component: <Login />,
    settings: {
      usePadding: false,
      pageTitle: 'Zaloguj się',
      secure: false,
      adminRoute: false,
    },
  },
  {
    path: '*',
    component: <NotFound />,
    settings: {
      usePadding: false,
      pageTitle: 'Ooopss...',
      secure: false,
      adminRoute: false,
    },
  },
];

export default DefaultRoutes;
