import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import TachoLogo from '../../../images/TACHOAPPLOGO.webp';

export default function Login() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleLogin = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (await auth?.logIn(username, password)) {
      const isLogged = await auth?.isLogged();
      const isAdmin = await auth?.isAdmin();

      if (isAdmin && isLogged) {
        navigate('/admin/home');
      }
    }
  };

  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    auth?.setErrorMessage('');
    setUsername('');
    setPassword('');
  };

  // TODO - Refactor
  return (
    <>
      {/* Login form */}
      <div className="grid place-items-center">
        <div className="card mt-20 w-full max-w-xl p-4 pt-0 sm:p-5">
          <div className="flex justify-center">
            <img src={TachoLogo} alt="Logo" className="h-40" />
          </div>
          <div className="flex justify-center text-center pb-4 -mt-4">
            <p className="text-xl font-semibold text-primary dark:text-accent-light">
              Logowanie
            </p>
          </div>
          <div className="space-y-4">
            <label className="block">
              <span>Nazwa użytkownika</span>
              <input
                className="form-input mt-1.5 w-full rounded-lg bg-slate-150 px-3 py-2 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                placeholder="Wprowadź nazwę użytkownika"
                type="text"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </label>
            <label className="block">
              <span>Hasło</span>
              <input
                className="form-input mt-1.5 w-full rounded-lg bg-slate-150 px-3 py-2 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                placeholder="Wprowadź hasło"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </label>
            {auth?.errorMessage && (
              <div className="text-red-500 mt-2">{auth.errorMessage}</div>
            )}
            <div className="flex justify-center space-x-2 pt-4">
              <button
                className="btn min-w-[7rem] border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                onClick={handleCancel}>
                Anuluj
              </button>
              <button
                className="btn min-w-[7rem] bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                onClick={handleLogin}>
                Zaloguj się
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
